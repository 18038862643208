import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { ToastContainer, Slide, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import SEO from '~/components/seo'
import { ContainerNarrow } from '~/utils/styles'

import ProductGrid from '~/components/ProductGrid'

import styled from '@emotion/styled'

import { breakpoints } from '~/utils/styles'
import { render } from 'react-dom'

const WishlistTable = styled.ul`
  padding: 0;
  list-style: none;
  li {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem 0;
    align-items: center;
  }
`

export const Button = styled.button`
  background-color: transparent;
  color: white;
  font-size: 2.4rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  &:focus,
  &:hover {
    outline: 0;
  }
`

const Wishlist = () => {
  let initItems =
    typeof window !== `undefined`
      ? JSON.parse(localStorage.getItem('offormWishlist'))
      : []

  if (initItems === null) initItems = []

  const [items, setItems] = useState(initItems)

  const handleRemove = i => {
    const newList = [...items]
    newList.splice(i, 1)
    setItems(newList)

    if (typeof window !== `undefined`) {
      localStorage.setItem('offormWishlist', JSON.stringify(newList))
    }

    toast.configure()
    toast.dark('Product removed from wishlist', {
      position: 'bottom-right',
      autoClose: 3000,
      transition: Slide,
    })
  }

  console.log(items)

  return (
    <ContainerNarrow>
      <SEO title="Wishlist" />
      <h1>Wishlist</h1>
      <WishlistTable>
        {items.length ? (
          items.map((item, i) => {
            return (
              <li key={i}>
                <Link to={`/product/${item.handle}`}>{item.name}</Link>
                <Button onClick={() => handleRemove(i)}>&times;</Button>
              </li>
            )
          })
        ) : (
          <p>No items in wishlist</p>
        )}
      </WishlistTable>
    </ContainerNarrow>
  )
}

export default Wishlist
